import React, { useState } from "react";
import arrow from "../assets/arrow-back.svg";
import plus from "../assets/plus.svg";
import { useNavigate } from "react-router-dom";
import useApp from "../context/AppContext";
import axiosInsatnce from "../utils/axios";
import { toast } from "react-toastify";
import axios from "axios";
import useAuth from "../context/AuthContext";

const AddProductPage = () => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [previewImages, setPreviewImages] = useState({});
  const [uploadProductData, setUploadProductData] = useState({});
  const { isLoading, setIsLoading, allCategoriesData } = useApp();
  const { token } = useAuth();
  // function fileHandler(e, index) {
  //   const files = e.target.files;
  //   const fileArray = Array.from(files);
  //   setSelectedFiles((prevFiles) => {
  //     prevFiles[index] = fileArray[0];
  //     return [...prevFiles];
  //   });

  //   const fileURL = URL.createObjectURL(fileArray[0]);
  //   console.log(fileURL, fileArray[0], fileArray);
  //   setPreviewImages((prevImages) => {
  //     prevImages[index] = fileURL;
  //     return [...prevImages];
  //   });
  // }
  function fileHandler(e, FileType) {
    const files = e.target.files[0];
    // const fileArray = Array.from(files);
    const fileURL = URL.createObjectURL(files);
    console.log(fileURL);
    setPreviewImages(fileURL);
    switch (FileType) {
      case "imageOne":
        setSelectedFiles({
          ...selectedFiles,
          imageOne: files,
        });
        setPreviewImages({
          ...previewImages,
          imagePreviewOne: fileURL,
        });
        break;
      case "imageTwo":
        setSelectedFiles({
          ...selectedFiles,
          imageTwo: files,
        });
        setPreviewImages({
          ...previewImages,
          imagePreviewTwo: fileURL,
        });
        break;
      case "imageThree":
        setSelectedFiles({
          ...selectedFiles,
          imageThree: files,
        });
        setPreviewImages({
          ...previewImages,
          imagePreviewThree: fileURL,
        });
        break;
      case "imageFour":
        setSelectedFiles({
          ...selectedFiles,
          imageFour: files,
        });
        setPreviewImages({
          ...previewImages,
          imagePreviewFour: fileURL,
        });
        break;
      case "imageFive":
        setSelectedFiles({
          ...selectedFiles,
          imageFive: files,
        });
        setPreviewImages({
          ...previewImages,
          imagePreviewFive: fileURL,
        });
        break;

      default:
        break;
    }
  }

  const {
    imagePreviewOne,
    imagePreviewTwo,
    imagePreviewThree,
    imagePreviewFour,
    imagePreviewFive,
  } = previewImages;
  const { imageOne, imageTwo, imageThree, imageFour, imageFive } =
    selectedFiles;

  const handleChange = (e, inputType) => {
    switch (inputType) {
      case "title":
        setUploadProductData({
          ...uploadProductData,
          title: e.target.value,
        });
        break;
      case "price":
        setUploadProductData({
          ...uploadProductData,
          price: e.target.value,
        });
        break;
      case "description":
        setUploadProductData({
          ...uploadProductData,
          description: e.target.value,
        });
        break;
      case "file":
        setUploadProductData({
          ...uploadProductData,
          file: e.target.files[0],
        });
        break;
      case "category":
        setUploadProductData({
          ...uploadProductData,
          category: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  const { title, description, price, category } = uploadProductData;
  let productData = {
    title,
    description,
    price: parseFloat(price),
    category,
  };

  const categoryEnum = [
    {
      id: 1,
      name: "Student or Prospective Student",
      value: "Student",
    },
    {
      id: 1,
      name: "Early Career Professional",
      value: "EarlyCareerProfessional",
    },
    {
      id: 1,
      name: "Mid Career Professional",
      value: "MidCareerProfessional",
    },
  ];

  // const uploadToCloudinary = async (imageFiles) => {
  //   const formDataArray = imageFiles.map((file) => {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append(
  //       "upload_preset",
  //       process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
  //     );
  //     return formData;
  //   });

  //   const uploadResponses = await Promise.all(
  //     formDataArray.map((formData) =>
  //       axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData)
  //     )
  //   );

  //   return uploadResponses.map((response) => response.data.secure_url);
  // };
  const uploadToCloudinary = async (imageFiles) => {
    // const formDataArray = imageFiles.map((file) => {
    const formData = new FormData();
    formData.append("file", imageFiles);
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    );
    //   return formData;
    // });

    const uploadResponses = await axios.post(
      process.env.REACT_APP_CLOUDINARY_URL,
      formData
    );

    return uploadResponses.data.secure_url;
  };

  const handleUploadProduct = async (e) => {
    e.preventDefault();
    if (
      !uploadProductData.title ||
      !uploadProductData.price ||
      !uploadProductData.description ||
      !uploadProductData.file ||
      !imageOne ||
      !imageTwo ||
      !imageOne ||
      !imageOne ||
      !imageFive
    ) {
      toast.error("Please provide the info for the book");
      return;
    }
    setIsLoading(true);

    let imageUrl1 = await uploadToCloudinary(imageOne);
    let imageUrl2 = await uploadToCloudinary(imageTwo);
    let imageUrl3 = await uploadToCloudinary(imageThree);
    let imageUrl4 = await uploadToCloudinary(imageFour);
    let imageUrl5 = await uploadToCloudinary(imageFive);
    // if (selectedFiles.length > 0) {

    // Handle the image URLs as needed
    // }
    // console.log("Image URLs:", imageUrls);
    const formData = new FormData();
    formData.append("pdf", uploadProductData.file);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("imageOne", imageUrl1);
    formData.append("imageTwo", imageUrl2);
    formData.append("imageThree", imageUrl3);
    formData.append("imageFour", imageUrl4);
    formData.append("imageFive", imageUrl5);

    console.log(Object.entries(formData.values()));

    try {
      productData = {
        // images: imageUrls,
        pdf: uploadProductData.file,
        ...productData,
      };
      console.log(productData);

      const res = await axiosInsatnce.post(
        `/api/v1/admin/uploadproduct/${category}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            // "content-type": uploadProductData?.file?.type,
            // "content-length": `${uploadProductData?.file?.size}`,
          },
        }
      );
      if (res.success) {
        toast.success("Product uploaded successfully");
        window.location.href = "/dashboard";
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error("An Error Occurred");
      window.location.href = "/dashboard/upload";
    } finally {
      setIsLoading(false);
      setUploadProductData({
        title: " ",
        description: " ",
        price: " ",
        category: " ",
        file: " ",
      });
      setPreviewImages({
        imagePreviewOne: " ",
        imagePreviewTwo: " ",
        imagePreviewThree: " ",
        imagePreviewFour: " ",
        imagePreviewFive: " ",
      });
      setSelectedFiles({
        imageOne: " ",
        imageTwo: " ",
        imageThree: " ",
        imageFour: " ",
        imageFive: " ",
      });
    }
    console.log(productData);
    console.log(uploadProductData);
  };
  if (isLoading) {
    const customId = "custom-id-yes";
    toast.loading("uploading book", {
      toastId: customId,
    });
  } else {
    toast.dismiss();
  }

  console.log(uploadProductData);

  return (
    <>
      <div className="w-full mt-32 px-10 mb-16">
        <button className="flex items-center mb-10">
          <img
            onClick={() => navigate(-1)}
            src={arrow}
            alt=""
            className="w-[30px] h-[30px]"
          />
          <h4 className="text-[17px] text-[#005593] ml-2">Back</h4>
        </button>
        <div className="bg-white w-full h-full p-8 rounded-[30px]">
          <form onSubmit={handleUploadProduct}>
            <h3 className="text-[25px] text-[#005593] font-semibold">
              Upload products context & cover photo
            </h3>
            <div>
              <div className="grid grid-cols-4 w-full mt-6 gap-y-9">
                <label
                  style={{ backgroundColor: "rgba(0, 85, 147, 0.20)" }}
                  className="border-2 border-[#005593] rounded-[20px] w-[150px] h-[150px] relative overflow-hidden"
                >
                  {!imagePreviewOne ? (
                    <img
                      src={plus}
                      className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={imagePreviewOne}
                      className="w-full h-full object-center object-cover"
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    className="hidden w-full h-full"
                    onChange={(e) => fileHandler(e, "imageOne")}
                  />
                </label>
                <label
                  style={{ backgroundColor: "rgba(0, 85, 147, 0.20)" }}
                  className="border-2 border-[#005593] rounded-[20px] w-[150px] h-[150px] relative overflow-hidden"
                >
                  {!imagePreviewTwo ? (
                    <img
                      src={plus}
                      className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={imagePreviewTwo}
                      className="w-full h-full object-center object-cover"
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    className="hidden w-full h-full"
                    onChange={(e) => fileHandler(e, "imageTwo")}
                  />
                </label>
                <label
                  style={{ backgroundColor: "rgba(0, 85, 147, 0.20)" }}
                  className="border-2 border-[#005593] rounded-[20px] w-[150px] h-[150px] relative overflow-hidden"
                >
                  {!imagePreviewThree ? (
                    <img
                      src={plus}
                      className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={imagePreviewThree}
                      className="w-full h-full object-center object-cover"
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    className="hidden w-full h-full"
                    onChange={(e) => fileHandler(e, "imageThree")}
                  />
                </label>
                <label
                  style={{ backgroundColor: "rgba(0, 85, 147, 0.20)" }}
                  className="border-2 border-[#005593] rounded-[20px] w-[150px] h-[150px] relative overflow-hidden"
                >
                  {!imagePreviewFour ? (
                    <img
                      src={plus}
                      className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={imagePreviewFour}
                      className="w-full h-full object-center object-cover"
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    className="hidden w-full h-full"
                    onChange={(e) => fileHandler(e, "imageFour")}
                  />
                </label>
                <label
                  style={{ backgroundColor: "rgba(0, 85, 147, 0.20)" }}
                  className="border-2 border-[#005593] rounded-[20px] w-[150px] h-[150px] relative overflow-hidden"
                >
                  {!imagePreviewFive ? (
                    <img
                      src={plus}
                      className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={imagePreviewFive}
                      className="w-full h-full object-center object-cover"
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    className="hidden w-full h-full"
                    onChange={(e) => fileHandler(e, "imageFive")}
                  />
                </label>
              </div>
            </div>
            <div className="flex w-[85%] mt-10">
              <div className="w-[65%] mr-9">
                <div className="flex flex-col w-full">
                  <label className="text-[25px] text-[#005593] font-semibold mb-1">
                    Title
                  </label>
                  <input
                    onChange={(e) => handleChange(e, "title")}
                    className="w-full border-2 border-[#808080] rounded-[10px] h-[50px] px-2 py-3"
                  />
                </div>
                <div className="flex flex-col w-full my-7">
                  <label className="text-[25px] text-[#005593] font-semibold mb-1">
                    Price
                  </label>
                  <input
                    onChange={(e) => handleChange(e, "price")}
                    className="w-full border-2 border-[#808080] rounded-[10px] h-[50px] px-2 py-3"
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-[25px] text-[#005593] font-semibold mb-1">
                  Description
                </label>
                <textarea
                  onChange={(e) => handleChange(e, "description")}
                  className="w-full h-[170px] border-2 border-[#808080] rounded-[10px] px-2 py-3"
                ></textarea>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex flex-col my-7 w-[50%]">
                <label className="text-[25px] text-[#005593] font-semibold mb-1">
                  Upload products file
                </label>
                <input
                  type="file"
                  onChange={(e) => handleChange(e, "file")}
                  // onChange={(e) => handlePdf(e)}
                  className="w-full border-2 border-[#808080] rounded-[10px] h-[50px] px-2 py-3"
                />
              </div>
              <select
                onChange={(e) => handleChange(e, "category")}
                name=""
                id=""
                className="h-[50px] px-2 py-3"
              >
                <option value="">Select Category</option>
                {allCategoriesData?.map((category, index) => {
                  return (
                    <option key={index} value={category?._id}>
                      {category?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <button className="capitalize text-[15px] text-[#fff] bg-[#005593] w-[40%] mt-6 py-3 rounded-[10px]">
              Upload
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProductPage;
