import "./App.css";
import { RouterProvider } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import useAuth from "./context/AuthContext";

function App({ router }) {
  const { currentUser } = useAuth();
  return (
    <>
      <AppContextProvider user={currentUser}>
        <RouterProvider router={router} />
      </AppContextProvider>
    </>
  );
}

export default App;
