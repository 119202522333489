import React from "react";
import { Link, useLoaderData } from "react-router-dom";
import next from "../assets/next.svg";
import useAuth from "../context/AuthContext";
import useApp from "../context/AppContext";
import axios from "../utils/axios";

const DashboardPage = () => {
  const { currentUser } = useAuth();
  const { totalProducts, totalNumbers } = useApp();

  const totalTransactions = useLoaderData();
  console.log(totalTransactions);
  return (
    <>
      <div className="w-full mt-32 px-7">
        <h2 className="text-[#005593] text-[25px] font-medium">
          Welcome,{" "}
          {currentUser.firstname.charAt(0).toUpperCase() +
            "" +
            currentUser.firstname.slice(1).toLowerCase()}{" "}
          👋
        </h2>
        <div className="mt-5 grid grid-cols-3 gap-8">
          <div className="bg-white col-span-1 h-[150px] rounded-[20px] p-4">
            <p className="text-[#005593] text-[15px] font-medium">
              Total amount of transactions
            </p>
            <h3 className="text-[#005593] text-[50px] font-medium">
              {totalNumbers}
            </h3>
          </div>
          <div className="bg-white col-span-1 h-[150px] rounded-[20px] p-4">
            <p className="text-[#005593] text-[15px] font-medium">
              Total amount of products
            </p>
            <h3 className="text-[#005593] text-[50px] font-medium">
              {totalProducts}
            </h3>
          </div>
          <div className="bg-white col-span-1 h-[150px] rounded-[20px] p-4">
            <p className="text-[#005593] text-[15px] font-medium">
              Total amount gotten
            </p>
            <h3 className="text-[#005593] text-[50px] font-medium">
              ${totalTransactions}
            </h3>
          </div>
          <Link
            to={"/product"}
            className="bg-white col-span-1 h-[150px] rounded-[20px] p-4 flex items-center justify-between"
          >
            <p className="text-[#005593] text-[40px] font-semibold">
              All products
            </p>
            <img src={next} alt="" />
          </Link>
          <Link
            to={"/payment"}
            className="bg-white col-span-1 h-[150px] rounded-[20px] p-4 flex items-center justify-between"
          >
            <p className="text-[#005593] text-[40px] font-semibold">
              All payments
            </p>
            <img src={next} alt="" />
          </Link>
          <Link
            to={"/upload"}
            className="bg-white col-span-1 h-[150px] rounded-[20px] p-4 flex items-center justify-between"
          >
            <p className="text-[#005593] text-[40px] font-semibold">Upload</p>
            <img src={next} alt="" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;

export const totalTransactionsLoader = async () => {
  const res = await axios.get("/api/v1/admin/totaltransactions");

  return res?.data?.totalAmount;
};
