import { createContext, useContext, useEffect, useState } from "react";
import axios from "../utils/axios";
import useAuth, { getAuth } from "./AuthContext";
const AppContext = createContext({
  totalProducts: null,
  totalNumbers: null,
  isLoading: false,
  setIsLoading: () => {},
  allCategoriesData: null,
});
export function AppContextProvider({ children, user }) {
  const [totalProducts, setTotalProducts] = useState();
  const [totalNumbers, setTotalNumbers] = useState();
  const [allProductsData, setAllProductsData] = useState([]);
  const [allCategoriesData, setAllCategoriesData] = useState();
  const [categoryDataId, setCategoryDataId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    if (user) {
      const getTotalProducts = async () => {
        try {
          const response = await axios.get("/api/v1/admin/totalproduct", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setTotalProducts(response.data.totalProducts);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      };
      getTotalProducts();
    }
  }, [token, user]);
  useEffect(() => {
    if (user) {
      const getTotalNumbers = async () => {
        try {
          const response = await axios.get("/api/v1/admin/totalnumber", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setTotalNumbers(response.data.totalTransactions);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      };
      getTotalNumbers();
    }
  }, [token, user]);

  useEffect(() => {
    if (user) {
      const fetchAllProducts = async () => {
        try {
          const res = await axios.get("/api/v1/admin/allproduct", {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      };
      fetchAllProducts();
    }
  }, [token, user]);
  useEffect(() => {
    if (user) {
      const fetchAllProducts = async () => {
        try {
          const res = await axios.get("/api/v1/admin/allproduct", {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      };
      fetchAllProducts();
    }
  }, [token, user]);
  useEffect(() => {
    if (user) {
      const fetchAllCategories = async () => {
        try {
          const res = await axios.get("/api/v1/admin/allcategories", {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log(res);
          setAllCategoriesData(res.data.categories);
        } catch (error) {
          console.log(error);
        }
      };
      fetchAllCategories();
    }
  }, [token, user]);
  useEffect(() => {
    if (user) {
      const fetchSingleCategory = async () => {
        try {
          const res = await axios.get(
            `/api/v1/admin/onecategory/${allCategoriesData?.[0]?._id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          console.log(res);
          // setAllCategoriesData(res.data.categories);
        } catch (error) {
          console.log(error);
        }
      };
      allCategoriesData?.[0]?._id && fetchSingleCategory();
    }
  }, [token, user, allCategoriesData]);

  return (
    <AppContext.Provider
      value={{
        totalProducts,
        totalNumbers,
        isLoading,
        setIsLoading,
        allCategoriesData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default function useApp() {
  return useContext(AppContext);
}
