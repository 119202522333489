import React from "react";
import CardLayout from "../components/card/CardLayout";
import CardContent from "../components/card/CardContent";
import book from "../assets/book.png";
import { useNavigate } from "react-router-dom";

const ProductPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full mt-32 px-7 pb-20">
        <h2 className="text-[#005593] text-[25px] font-medium">
          Manage Products
        </h2>
        <div className="mt-20 grid grid-cols-3 gap-6 gap-y-14">
          {/* <CardLayout
            src={book}
            imgClass={
              "w-[250px] h-[150px] object-contain rounded-[20px] bg-white"
            }
            home
          >
            <CardContent
              title={"Rich dad poor dad"}
              price={"$24.99"}
              subtitle={"Sweet in the middle for days"}
              onClick={() => navigate("/product/detail/1")}
            />
          </CardLayout>
          <CardLayout
            src={book}
            imgClass={
              "w-[250px] h-[150px] object-contain rounded-[20px] bg-white"
            }
            home
          >
            <CardContent
              title={"Rich dad poor dad"}
              price={"$24.99"}
              subtitle={"Sweet in the middle for days"}
              onClick={() => navigate("/product/detail/1")}
            />
          </CardLayout>
          <CardLayout
            src={book}
            imgClass={
              "w-[250px] h-[150px] object-contain rounded-[20px] bg-white"
            }
            home
          >
            <CardContent
              title={"Rich dad poor dad"}
              price={"$24.99"}
              subtitle={"Sweet in the middle for days"}
              onClick={() => navigate("/product/detail/1")}
            />
          </CardLayout>
          <CardLayout
            src={book}
            imgClass={
              "w-[250px] h-[150px] object-contain rounded-[20px] bg-white"
            }
            home
          >
            <CardContent
              title={"Rich dad poor dad"}
              price={"$24.99"}
              subtitle={"Sweet in the middle for days"}
              onClick={() => navigate("/product/detail/1")}
            />
          </CardLayout>
          <CardLayout
            src={book}
            imgClass={
              "w-[250px] h-[150px] object-contain rounded-[20px] bg-white"
            }
            home
          >
            <CardContent
              title={"Rich dad poor dad"}
              price={"$24.99"}
              subtitle={"Sweet in the middle for days"}
              onClick={() => navigate("/product/detail/1")}
            />
          </CardLayout>
          <CardLayout
            src={book}
            imgClass={
              "w-[250px] h-[150px] object-contain rounded-[20px] bg-white"
            }
            home
          >
            <CardContent
              title={"Rich dad poor dad"}
              price={"$24.99"}
              subtitle={"Sweet in the middle for days"}
              onClick={() => navigate("/product/detail/1")}
            />
          </CardLayout> */}
        </div>
        <h3 className="text-center text-[30px] font-bold text-[#005593]">
          You Don't Have Any Product Yet
        </h3>
      </div>
    </>
  );
};

export default ProductPage;
