import React, { useEffect, useState } from "react";
import arrow from "../assets/arrow-back.svg";
import user from "../assets/user.png";
import useAuth from "../context/AuthContext";

const ProfilePage = () => {
  const { currentUser } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isEditing, setIsEditing] = useState(true);
  const isTrueOrIsFalse = currentUser?.isAdmin === true ? "Yes" : "No";
  const first =
    currentUser?.firstname?.charAt(0).toUpperCase() +
    currentUser?.firstname?.slice(1).toLowerCase();
  const last =
    currentUser?.lastname?.charAt(0).toUpperCase() +
    currentUser?.lastname?.slice(1).toLowerCase();

  const toggleEditHandler = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    setFirstName(first || "");
    setLastName(last || "");
    setIsAdmin(isTrueOrIsFalse || "");
    setPhoneNo(currentUser?.phone || "");
    setEmailAddress(currentUser?.email || "");
  }, [currentUser?.email, currentUser?.phone, first, isTrueOrIsFalse, last]);

  return (
    <>
      <div className="w-full mt-32 px-10 mb-16">
        <button className="flex items-center mb-10">
          <img src={arrow} alt="" className="w-[30px] h-[30px]" />
          <h4 className="text-[17px] text-[#005593] ml-2">Back</h4>
        </button>
        <form className="bg-white w-full h-screen p-8 rounded-[30px]">
          <div>
            <div className="flex justify-between items-center">
              <img
                className="w-[150px] h-[150px] rounded-full object-cover"
                alt=""
                src={user}
              />
              <button
                type="button"
                onClick={() => toggleEditHandler()}
                className="text-[#F00] text-[18px] font-semibold"
              >
                Edit Profile
              </button>
            </div>
          </div>
          <fieldset className="border border-solid border-[#D9D9D9] py-5 px-4 w-[75%] h-[260px] rounded-[12px] mt-24">
            <legend className="text-[#005593] text-[14px] font-semibold ml-10">
              Personal Info
            </legend>
            <div className="grid grid-cols-3 mt-12 gap-4">
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[12px] text-[#005593] font-medium mb-2"
                >
                  First name
                </label>
                <input
                  className="rounded-[2px] border border-[#F1F1F1] px-2 py-2 text-[#005593] text-[12px]"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={isEditing}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[12px] text-[#005593] font-medium mb-2"
                >
                  Last name
                </label>
                <input
                  className="rounded-[2px] border border-[#F1F1F1] px-2 py-2 text-[#005593] text-[12px]"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={isEditing}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[12px] text-[#005593] font-medium mb-2"
                >
                  IsAdmin
                </label>
                <input
                  className="rounded-[2px] border border-[#F1F1F1] px-2 py-2 text-[#005593] text-[12px]"
                  value={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.value)}
                  disabled={isEditing}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[12px] text-[#005593] font-medium mb-2"
                >
                  Phone No
                </label>
                <input
                  className="rounded-[2px] border border-[#F1F1F1] px-2 py-2 text-[#005593] text-[12px]"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  disabled={isEditing}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor=""
                  className="text-[12px] text-[#005593] font-medium mb-2"
                >
                  Email address
                </label>
                <input
                  className="rounded-[2px] border border-[#F1F1F1] px-2 py-2 text-[#005593] text-[12px]"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  disabled={isEditing}
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};

export default ProfilePage;
