import React from "react";
import book from "../assets/book.png";

const ProductDetailPage = () => {
  return (
    <>
      <div className="w-full mt-32 px-7 pb-20">
        <div className="flex">
          <div className="w-[40%]">
            <img src={book} alt="" className="w-full h-[400px] object-cover" />
            <div className="flex flex-wrap justify-center">
              <img
                src={book}
                className="w-[100px] h-[100px] object-cover"
                alt=""
              />
              <img
                src={book}
                className="w-[100px] h-[100px] object-cover"
                alt=""
              />
              <img
                src={book}
                className="w-[100px] h-[100px] object-cover"
                alt=""
              />
              <img
                src={book}
                className="w-[100px] h-[100px] object-cover"
                alt=""
              />
            </div>
          </div>
          <div className="mt-8 w-[60%]">
            <div className="border-b border-b-[#005593] w-full pb-14">
              <h2 className="text-[#005593] text-[35px] font-semibold">
                Rich dad poor dad
              </h2>
              <p className="text-[#005593] text-[15px] font-medium mt-3">
                Lörem ipsum reska trenade för att parativ de hikikomori ludode.
              </p>
            </div>

            <p className="text-[#005593] text-[35px] font-semibold mt-3">
              $24.99
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgba(238, 238, 238, 0.50)",
          }}
          className="h-[400px] w-[75%] rounded-[20px] px-10 py-4 mt-20"
        >
          <h3 className="text-[45px] font-semibold text-[#005593]">
            Description
          </h3>
          <p className="text-[23px] font-normal text-[#005593] mt-3">
            Lörem ipsum rocka fett makross. Jylig pong. Sade tufon, mivis.
            Hoforas fol. Ter disat. Kron geode eurose, men stenohet. Spektiga
            vaska medelgen inte tetraskapet. Ivis nydat. Juning sanirat.
            Vajyligt. Autosam jyssa frontbibliotekarie. Yv stenologi,
            kollektomat inklusive metrotropi. Neviledes tesosam mytoform.
          </p>
        </div>
      </div>
    </>
  );
};

export default ProductDetailPage;
