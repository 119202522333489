import React from "react";

const PaymentPage = () => {
  return (
    <>
      <div className="w-full mt-32 px-7">
        <h2 className="text-[#005593] font-medium text-[50px]">All Payment</h2>
        <div className="w-full mt-10">
          <table
            className="w-full bg-white rounded-[10px] border border-[#B1BAC9] overflow-hidden text-left"
            border={1}
          >
            <thead className="text-[#005593] font-medium text-[14px]">
              <th className="px-5 py-5">S/No</th>
              <th className="px-5 py-5">Customer</th>
              <th className="px-5 py-5">Email</th>
              <th className="px-5 py-5">Product</th>
              <th className="px-5 py-5">Amount</th>
              <th className="px-5 py-5">Reference</th>
              {/* <th className="px-5 py-5">Action</th> */}
            </thead>
            <tbody className="text-[#005593] font-normal text-[14px]">
              {/* <tr className="border">
                <td className="px-5 py-5">1</td>
                <td className="px-5 py-5">Abdul Lawal</td>
                <td className="px-5 py-5">Admin002@ghostriders</td>
                <td className="px-5 py-5">Rich dad poor dad</td>
                <td className="px-5 py-5">$3.24</td>
                <td className="px-5 py-5">c6b3788e-703a</td>
                <td className="px-5 py-5">icon</td>
              </tr>
              <tr className="border">
                <td className="px-5 py-5">2</td>
                <td className="px-5 py-5">Olawale Samule</td>
                <td className="px-5 py-5">Admin002@ghostriders</td>
                <td className="px-5 py-5">Rich dad poor dad</td>
                <td className="px-5 py-5">$41.25</td>
                <td className="px-5 py-5">a5359851f72d</td>
                <td className="px-5 py-5">icon</td>
              </tr>
              <tr className="border">
                <td className="px-5 py-5">3</td>
                <td className="px-5 py-5">Olawale Samule</td>
                <td className="px-5 py-5">Admin002@ghostriders</td>
                <td className="px-5 py-5">Rich dad poor dad</td>
                <td className="px-5 py-5">$41.25</td>
                <td className="px-5 py-5">a5359851f72d</td>
                <td className="px-5 py-5">icon</td>
              </tr>
              <tr className="border">
                <td className="px-5 py-5">4</td>
                <td className="px-5 py-5">Olawale Samule</td>
                <td className="px-5 py-5">Admin002@ghostriders</td>
                <td className="px-5 py-5">Rich dad poor dad</td>
                <td className="px-5 py-5">$41.25</td>
                <td className="px-5 py-5">a5359851f72d</td>
                <td className="px-5 py-5">icon</td>
              </tr> */}
            </tbody>
          </table>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default PaymentPage;
