import React from "react";

const Home = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.2172 3.95631C10.159 3.90068 10.0817 3.86963 10.0012 3.86963C9.92069 3.86963 9.84331 3.90068 9.78516 3.95631L2.59375 10.8262C2.56321 10.8554 2.53891 10.8906 2.52233 10.9294C2.50574 10.9683 2.49722 11.0101 2.49727 11.0524L2.49609 17.5001C2.49609 17.8316 2.62779 18.1495 2.86221 18.3839C3.09663 18.6184 3.41457 18.7501 3.74609 18.7501H7.5C7.66576 18.7501 7.82473 18.6842 7.94194 18.567C8.05915 18.4498 8.125 18.2908 8.125 18.1251V12.8126C8.125 12.7297 8.15792 12.6502 8.21653 12.5916C8.27513 12.533 8.35462 12.5001 8.4375 12.5001H11.5625C11.6454 12.5001 11.7249 12.533 11.7835 12.5916C11.8421 12.6502 11.875 12.7297 11.875 12.8126V18.1251C11.875 18.2908 11.9408 18.4498 12.0581 18.567C12.1753 18.6842 12.3342 18.7501 12.5 18.7501H16.2523C16.5839 18.7501 16.9018 18.6184 17.1362 18.3839C17.3706 18.1495 17.5023 17.8316 17.5023 17.5001V11.0524C17.5024 11.0101 17.4939 10.9683 17.4773 10.9294C17.4607 10.8906 17.4364 10.8554 17.4059 10.8262L10.2172 3.95631Z"
        fill={color}
      />
      <path
        d="M19.1762 9.53711L16.2543 6.7418V2.5C16.2543 2.33424 16.1885 2.17527 16.0713 2.05806C15.954 1.94085 15.7951 1.875 15.6293 1.875H13.7543C13.5886 1.875 13.4296 1.94085 13.3124 2.05806C13.1952 2.17527 13.1293 2.33424 13.1293 2.5V3.75L10.8668 1.58672C10.6551 1.37266 10.3403 1.25 10 1.25C9.66095 1.25 9.34689 1.37266 9.13517 1.58711L0.826577 9.53633C0.583609 9.7707 0.55314 10.1562 0.774234 10.4102C0.829754 10.4742 0.897734 10.5264 0.974038 10.5633C1.05034 10.6003 1.13337 10.6214 1.21808 10.6253C1.30278 10.6291 1.38739 10.6157 1.46675 10.5859C1.54611 10.556 1.61856 10.5103 1.6797 10.4516L9.78517 2.70625C9.84333 2.65062 9.92071 2.61957 10.0012 2.61957C10.0817 2.61957 10.159 2.65062 10.2172 2.70625L18.3235 10.4516C18.4429 10.5661 18.6028 10.6286 18.7682 10.6253C18.9336 10.6221 19.091 10.5534 19.2059 10.4344C19.4457 10.1859 19.4258 9.77578 19.1762 9.53711Z"
        fill={color}
      />
    </svg>
  );
};

export default Home;
