import React, { useState } from "react";
import { EyeSlash, Eye } from "iconsax-react";
import axios from "../utils/axios";
import useAuth from "../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  const {
    isLogging,
    setIsLogging,
    updateCurrentUser,
    setAuthToken,
    setCurrentUserId,
  } = useAuth();
  let navigate = useNavigate();
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const viewPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const data = {
    email: enteredEmail,
    password: enteredPassword,
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    if (!enteredEmail || !enteredPassword) {
      console.log("Please enter your email and password");
      setErrorMessage("Please enter your email and password");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return false;
    }
    setIsLogging(true);
    try {
      // const response = await axios.post(
      //   "/api/v1/auth/back/login",
      //   JSON.stringify(data)
      // );
      const response = await toast.promise(
        axios.post("/api/v1/auth/login", JSON.stringify(data)),
        {
          pending: "Logging in User",
          //   success: "Promise resolved 👌",
          //   error: "Promise rejected 🤯",
        }
      );
      console.log(response);
      if (response.success) {
        updateCurrentUser(response.data.user);
        setAuthToken(response.token);
        setCurrentUserId(response.data.user._id);
        window.location.href = "/dashboard";
        // navigate("/dashboard");
        toast.success(
          `Welcome ${response?.data?.user?.lastname} ${response?.data?.user?.firstname}`
        );
      }
    } catch (error) {
      toast.error(error?.error || error?.message);
    } finally {
      console.log("yess");
      setIsLogging(false);
      //   setEnteredEmail(" ");
      //   setEnteredPassword("");
    }
  };

  return (
    <>
      <div className="bg-[#005593] w-full h-screen flex flex-col place-content-center place-items-center">
        <div className="bg-white w-[40%] h-[500px] rounded-[10px] px-9 py-6">
          <h2 className="text-center text-[35px] font-medium">Admin Login</h2>
          <form
            className="mt-0 flex flex-col items-center w-full"
            autoComplete="new-password"
            onSubmit={loginHandler}
          >
            <div className="flex flex-col w-full my-6">
              <label
                className={`text-[25px] font-medium ${
                  errorMessage ? "text-red-600" : "text-[#005593]"
                }`}
              >
                Email
              </label>
              <input
                type="email"
                className={`border-2 ${
                  errorMessage ? "border-red-600" : "border-[#005593]"
                } rounded-[10px] w-full px-4 py-2 mt-2`}
                name=""
                autoComplete="new-password"
                value={enteredEmail}
                onChange={(e) => setEnteredEmail(e.target.value)}
              />
              <p className="text-[12px] text-red-600">
                {errorMessage ? errorMessage : null}
              </p>
            </div>
            <div className="flex flex-col w-full my-4 relative">
              <label
                className={`text-[25px] font-medium ${
                  errorMessage ? "text-red-600" : "text-[#005593]"
                }`}
              >
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className={`border-2 ${
                  errorMessage ? "border-red-600" : "border-[#005593]"
                } rounded-[10px] w-full px-4 py-2 mt-2`}
                name=""
                autoComplete="new-password"
                value={enteredPassword}
                onChange={(e) => setEnteredPassword(e.target.value)}
              />
              <p className="text-[12px] text-red-600">
                {errorMessage ? errorMessage : null}
              </p>
              <button
                type="button"
                className={`absolute right-5 ${
                  errorMessage ? "top-[52%]" : "top-[62%]"
                }`}
                onClick={viewPasswordHandler}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </button>
            </div>
            <button
              type="submit"
              disabled={isLogging}
              className="w-[50%] bg-[#005593] h-[50px] px-3 py-2 text-white text-[20px] font-medium rounded-[10px] mt-12"
            >
              {isLogging ? "Loading....." : "Login"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
