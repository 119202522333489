import React from "react";
import axios from "../utils/axios";
import { Link, NavLink, useLocation } from "react-router-dom";
import user from "../assets/user.png";
import Home from "../components/svg/Home";
import { getCurrentColor } from "../utils/utils";
import Product from "../components/svg/Product";
import Vector from "../components/svg/Vector";
import User from "../components/svg/User";
import Payment from "../components/svg/Payment";
import log from "../assets/logout.svg";
import useAuth from "../context/AuthContext";
import { toast } from "react-toastify";
import useApp from "../context/AppContext";

const SideBar = () => {
  const { currentUser, userId, logout } = useAuth();
  const { allCategoriesData } = useApp();
  const location = useLocation();
  function sidebarStyle({ isActive }) {
    return {
      backgroundColor: isActive ? "#D9D9D9" : "",
      color: isActive ? "#005593" : "#D9D9D9",
    };
  }
  const logoutHandler = async () => {
    try {
      const response = await axios.post("/api/v1/auth/logout");
      if (response.success) {
        logout();
        toast.success(response?.message);
        console.log(response);
        // window.location.href="/";
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(allCategoriesData);
  return (
    <>
      <aside
        className="bg-[#005593] w-1/5 h-screen flex flex-col justify-between items-center"
        style={{
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="w-full relative">
          <div
            style={{
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            }}
            className="bg-white w-full h-[80px] p-3  text-center absolute z-20 left-0"
          >
            <Link
              to={"/dashboard"}
              className="text-[35px] text-[#005593] font-medium"
            >
              Harnessville
            </Link>
          </div>
          <div className="flex flex-col items-center w-full py-5 px-5">
            <Link
              to={`/profile/${userId}`}
              className="flex flex-col items-center w-full pb-8 border-b-2 border-b-[#D9D9D9] mt-20"
            >
              <img
                src={user}
                className="w-[60px] h-[60px] rounded-full bg-white object-cover"
                alt=""
              />
              <h3 className="mt-4 text-white text-[17px] font-medium">
                {currentUser.lastname +
                  " " +
                  currentUser.firstname.charAt(0).toUpperCase() +
                  currentUser.firstname.slice(1).toLowerCase()}
              </h3>
            </Link>
            <nav className="pt-1 w-full">
              <ul className="w-full">
                <li className="my-4">
                  <NavLink
                    to={"/dashboard"}
                    className="w-full p-2 rounded-[10px] flex items-center duration-500"
                    style={sidebarStyle}
                  >
                    <Home color={getCurrentColor(location, "/dashboard")} />
                    <span className="ml-2">Dashboard</span>
                  </NavLink>
                </li>
                <li className="my-4">
                  <NavLink
                    to={"/product"}
                    className="w-full p-2 rounded-[10px] flex items-center duration-500"
                    style={sidebarStyle}
                  >
                    <Product color={getCurrentColor(location, "/product")} />
                    <span className="ml-2">Product</span>
                  </NavLink>
                </li>
                {allCategoriesData?.length !== 0 && (
                  <li className="my-4">
                    <NavLink
                      to={"/upload"}
                      className="w-full p-2 rounded-[10px] flex items-center duration-500"
                      style={sidebarStyle}
                    >
                      <Vector color={getCurrentColor(location, "/upload")} />
                      <span className="ml-2">Upload Product</span>
                    </NavLink>
                  </li>
                )}
                <li className="my-4">
                  <NavLink
                    to={"/create-category"}
                    className="w-full p-2 rounded-[10px] flex items-center duration-500"
                    style={sidebarStyle}
                  >
                    <Vector
                      color={getCurrentColor(location, "/create-category")}
                    />
                    <span className="ml-2">Create Catgeory</span>
                  </NavLink>
                </li>
                <li className="my-4">
                  <NavLink
                    to={"/payment"}
                    className="w-full p-2 rounded-[10px] flex items-center duration-500"
                    style={sidebarStyle}
                  >
                    <Payment color={getCurrentColor(location, "/payment")} />
                    <span className="ml-2">Payment</span>
                  </NavLink>
                </li>
                <li className="my-4">
                  <NavLink
                    to={`/profile/${userId}`}
                    className="w-full p-2 rounded-[10px] flex items-center duration-500"
                    style={sidebarStyle}
                  >
                    <User
                      color={getCurrentColor(location, `/profile/${userId}`)}
                    />
                    <span className="ml-2">Profile</span>
                  </NavLink>
                </li>
                {/* <li className="my-4">
                  <NavLink
                    to={"/change"}
                    className="w-full p-2 rounded-[10px] flex items-center duration-500"
                    style={sidebarStyle}
                  >
                    <span  className="ml-3">Change Password</span>
                  </NavLink>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div className="mb-4">
          <button
            className="flex items-end"
            type="button"
            onClick={() => logoutHandler()}
          >
            <img src={log} alt="" />
            <span className="text-[#F00] text-[13px] font-medium ml-1">
              Logout
            </span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default SideBar;
