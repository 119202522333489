import React, { useState } from "react";
import arrow from "../assets/arrow-back.svg";
import plus from "../assets/plus.svg";
import { useNavigate } from "react-router-dom";
import useApp from "../context/AppContext";
import axiosInsatnce from "../utils/axios";
import { toast } from "react-toastify";
import axios from "axios";
import useAuth from "../context/AuthContext";

const AddCategoryPage = () => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState();
  const [previewImages, setPreviewImages] = useState();
  const [uploadProductData, setUploadProductData] = useState({});
  const { isLoading, setIsLoading } = useApp();
  const { token } = useAuth();
  function fileHandler(e) {
    const files = e.target.files[0];
    // const fileArray = Array.from(files);
    setSelectedFiles(files);
    const fileURL = URL.createObjectURL(files);
    console.log(fileURL);
    setPreviewImages(fileURL);
  }
  console.log(selectedFiles);

  const handleChange = (e, inputType) => {
    switch (inputType) {
      case "name":
        setUploadProductData({
          ...uploadProductData,
          name: e.target.value,
        });
        break;
      case "typeOfCategory":
        setUploadProductData({
          ...uploadProductData,
          typeOfCategory: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  const { name, typeOfCategory } = uploadProductData;
  let productData = {
    name,
    typeOfCategory,
  };

  const categoryEnum = [
    {
      id: 1,
      name: "Student or Prospective Student",
      value: "Student",
    },
    {
      id: 2,
      name: "Early Career Professional",
      value: "EarlyCareerProfessional",
    },
    {
      id: 3,
      name: "Mid Career Professional",
      value: "MidCareerProfessional",
    },
    {
      id: 4,
      name: "Break into Supply chain",
      value: "BreakIntoSupplyChain",
    },
  ];

  const uploadToCloudinary = async (imageFiles) => {
    // const formDataArray = imageFiles.map((file) => {
    const formData = new FormData();
    formData.append("file", imageFiles);
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    );
    //   return formData;
    // });

    const uploadResponses = await axios.post(
      process.env.REACT_APP_CLOUDINARY_URL,
      formData
    );

    return uploadResponses.data.secure_url;
  };

  const handleUploadProduct = async (e) => {
    e.preventDefault();
    if (
      !uploadProductData.name ||
      !uploadProductData.typeOfCategory ||
      selectedFiles.length > 0
    ) {
      toast.error("Please provide the info for the book");
      return;
    }
    setIsLoading(true);

    let imageUrls;
    // if (selectedFiles.length > 0) {
    imageUrls = await uploadToCloudinary(selectedFiles);
    // Handle the image URLs as needed
    // }
    console.log("Image URLs:", imageUrls);
    try {
      productData = {
        image: imageUrls,
        ...productData,
      };
      console.log(productData);

      const res = await axiosInsatnce.post(
        "/api/v1/admin/createcategory",
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "App",
            // "content-type": uploadProductData?.file?.type,
            // "content-length": `${uploadProductData?.file?.size}`,
          },
        }
      );
      if (res.success) {
        toast.success("Category Created Succesfully");
        window.location.href = "/upload";
        // res.category
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedFiles(null);
      setPreviewImages(null);
      setUploadProductData({
        ...uploadProductData,
        typeOfCategory: " ",
      });
      setUploadProductData({
        ...uploadProductData,
        name: " ",
      });

      setIsLoading(false);
    }
    console.log(productData);
    console.log(uploadProductData);
  };
  if (isLoading) {
    const customId = "custom-id-yes";
    toast.loading("uploading book", {
      toastId: customId,
    });
  } else {
    toast.dismiss();
  }
  console.log(uploadProductData);
  //   window.location.href = "/upload";

  return (
    <>
      <div className="w-full mt-32 px-10 mb-16">
        <button className="flex items-center mb-10">
          <img
            onClick={() => navigate(-1)}
            src={arrow}
            alt=""
            className="w-[30px] h-[30px]"
          />
          <h4 className="text-[17px] text-[#005593] ml-2">Back</h4>
        </button>
        <div className="bg-white w-full h-[500px] p-8 rounded-[30px]">
          <form onSubmit={handleUploadProduct}>
            <h3 className="text-[25px] text-[#005593] font-semibold">
              Upload Category context & cover photo
            </h3>
            <div>
              <div className="grid grid-cols-4 w-full mt-6">
                {/* {[...Array(4)].map((_, index) => ( */}
                <label
                  // key={index}
                  style={{ backgroundColor: "rgba(0, 85, 147, 0.20)" }}
                  className="border-2 border-[#005593] rounded-[20px] w-[150px] h-[150px] relative overflow-hidden"
                >
                  {!previewImages ? (
                    <img
                      src={plus}
                      className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={previewImages}
                      className="w-full h-full object-center object-cover"
                      alt=""
                    />
                  )}
                  <input
                    type="file"
                    className="hidden w-full h-full"
                    onChange={(e) => fileHandler(e)}
                  />
                </label>
                {/* ))} */}
              </div>
            </div>
            <div className="flex w-[85%] mt-10">
              <div className="w-[65%] mr-9">
                <div className="flex flex-col w-full">
                  <label className="text-[25px] text-[#005593] font-semibold mb-1">
                    Name
                  </label>
                  <input
                    onChange={(e) => handleChange(e, "name")}
                    className="w-full border-2 border-[#808080] rounded-[10px] h-[50px] px-2 py-3"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between ">
                <select
                  onChange={(e) => handleChange(e, "typeOfCategory")}
                  //   name=""
                  //   id=""
                  className="h-[50px] px-2 py-3 pt-5"
                >
                  <option value="">Select Category</option>
                  {categoryEnum.map((category, index) => {
                    return (
                      <option key={index} value={category.value}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <button className="capitalize text-[15px] text-[#fff] bg-[#005593] w-[40%] float-right mt-6 py-3 rounded-[10px]">
              Upload
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCategoryPage;
