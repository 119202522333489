import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
export const getAuth = () => {
  try {
    const token = localStorage.getItem("Token");
    if (token) {
      console.log(token);
    }
    console.log(token);
    return token;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const AuthContext = createContext({
  currentUser: null,
  userId: null,
  updateCurrentUser: (value) => {},
  setAuthToken: (value) => {},
  setCurrentUserId: (id) => {},
  getAuth,
  setIsLogging: () => {},
  isLogging: false,
  token: "",
  logout: () => {},
});

export function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("CurrentUser")) || null
  );
  const [userId, setUserId] = useState(
    localStorage.getItem("CurrentUserId") || null
  );
  const [userToken, setUserToken] = useState(
    localStorage.getItem("Token") || null
  );
  const [isLogging, setIsLogging] = useState(false);

  const logout = (route) => {
    localStorage.removeItem("CurrentUser");
    localStorage.removeItem("CurrentUserId");
    localStorage.removeItem("Token");
    window.location.href = route ? route : "/";
  };
  const setAuthToken = (token) => {
    // token
    localStorage.setItem("Token", token);
    setUserToken(token);
    // : localStorage.getItem("Token");
  };
  const updateCurrentUser = (value) => {
    if (value) {
      localStorage.setItem("CurrentUser", JSON.stringify(value));
      setCurrentUser(value);
    }
  };
  const setCurrentUserId = (id) => {
    if (id) {
      localStorage.setItem("CurrentUserId", id);
      return;
    }
    setUserId(id);
  };
  useEffect(() => {
    const token = localStorage.getItem("Token");
    const currentUser = localStorage.getItem("CurrentUser");
    if (token) return setAuthToken(token);
    if (currentUser) updateCurrentUser(JSON.parse(currentUser));
  }, []);
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setAuthToken,
        logout,
        updateCurrentUser,
        getAuth,
        setCurrentUserId,
        userId,
        setIsLogging,
        isLogging,
        token: userToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
