import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import notification from "../assets/notification.svg";

const DashboardLayout = () => {
  return (
    <>
      <section className="w-full h-screen bg-[#F9F9F9] flex justify-between fixed">
        <SideBar />
        <div className="w-[78.8%] h-screen overflow-scroll relative z-10">
          <div className="w-full h-[80px] bg-white fixed left-0 z-30">
            <img
              src={notification}
              alt=""
              className="bg-[#F9F9F9] w-[40px] h-[40px] rounded-full float-right object-contain p-2 my-4 mx-6"
            />
          </div>
          <>
            <Outlet />
          </>
        </div>
      </section>
    </>
  );
};

export default DashboardLayout;
